@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');


@layer base {
    html {
        font-family: 'Montserrat';
        font-weight: 300;
        scroll-behavior: smooth;
    }

    #swiper {
        z-index: 0;
    }
}